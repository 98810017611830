const useHomePage = () => {
	const { data: appInit } = useAppInitData();
	const { isLimitOffer } = useFlipTheCoin();
	const { select } = useGamesState();
	const {
		data: pageData,
		pending: loading,
		refresh,
		jackpotGames,
		holdNLinksGames,
		gamesWithWeekGame,
		featuredGames,
		missedItGames
	} = useHomeData({ cached: true, server: true });
	const { data: restTournamentData } = useGetTournamentData();

	const banners = computed(() => {
		if (!appInit.value?.isGuest && appInit.value?.bannerPresetPackage) {
			return [
				{
					type: "bannerPresetPackage",
					...appInit.value.bannerPresetPackage
				},
				...(pageData.value?.banners || [])
			];
		}
		return pageData.value?.banners || [];
	});

	const questData = computed(() => pageData.value?.questData);
	const gameofWeek = computed(() => {
		if (!Array.isArray(pageData.value?.gameOfWeek)) {
			return null;
		}
		return select(pageData.value?.gameOfWeek)?.[0];
	});

	const filteredBanners = computed(
		() =>
			banners.value?.filter(({ type }) => {
				if (type === "quest") {
					return !!questData.value?.quest && questData.value?.quest?.questInfo?.type !== "season";
				}

				if (type === "challenge") {
					return questData.value?.quest?.questInfo?.type === "season";
				}

				if (type === "scratchBanner") {
					return appInit.value?.scratchCardLottery?.isActive;
				}

				if (type === "depositStreakBanner") {
					return appInit.value?.depositStreak?.isActive;
				}

				if (type === "bannerTournament") {
					return restTournamentData.value?.isActiveStatus;
				}

				return type !== "flipTheCoin" || !isLimitOffer.value;
			}) || []
	);

	const { games } = useGetGameFiltersData();

	const seoData = computed(() => pageData.value?.seo);

	const promotions = computed(() => pageData.value?.promotions || []);

	const yourPick = computed(() => {
		const reversedFavGames = [...(appInit.value?.favoriteGames || "")].reverse();
		const uniqueGames = [...new Set([...(appInit.value?.lastGames || []), ...reversedFavGames])];

		return uniqueGames.map((id) => games.value?.find((g: { id?: number }) => g.id === id)).filter(Boolean);
	});

	return {
		loading,
		pageData,
		games,
		refresh,
		featuredGames,
		missedItGames,
		jackpotGames,
		holdNLinksGames,
		promotions,
		gamesWithWeekGame,
		gameofWeek,
		banners,
		filteredBanners,
		questData,
		seoData,
		yourPick
	};
};

export default useHomePage;
